import React, { useState } from 'react';
import PaymentModal from './PaymentModal';

const CardComponent = ({ title, description, image, downloadLink, price }) => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <div className="col-md-4 mb-4">
            <div className="card">
                <img src={image} className="card-img-top" alt={title} />
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{description}</p>
                    <p className="card-text"><strong>Price: ${price}</strong></p>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" onClick={handleShow}>Buy Now</button>
                    </div>
                </div>
            </div>
            <PaymentModal show={showModal} handleClose={handleClose} downloadLink={downloadLink} price={price} />
        </div>
    );
};

export default CardComponent;
