import React, { useEffect, useState } from 'react';

const Header = ({logoText}) => {

  const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        fetch('/menu.json')
            .then((response) => response.json())
            .then((data) => setMenuItems(data))
            .catch((error) => console.error('Error fetching menu data:', error));
    }, []);


    return (
        <header className="header py-3">
            <div className="container d-flex justify-content-between align-items-center">
                <div className="logo d-flex align-items-center">
                    <img src="logo.png" alt="Logo" className="logo-image me-2" />
                    <h1 className="mb-0 text-white">{logoText}</h1>
                </div>
                <nav className="d-flex align-items-center">
                    {menuItems.map((item, index) => (
                        <a key={index} href={item.link} className="nav-link text-white me-3">
                            {item.name}
                        </a>
                    ))}
                </nav>
            </div>
        </header>
    );
};

export default Header;
