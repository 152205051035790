import React from 'react';
import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import axios from 'axios';
import abi from './abi';
import pancakeSwapRouterABI from './pancakeSwapRouterABI.json';
import ERC20ABI from './ERC20ABI.json';


const PaymentModal = ({ show, handleClose, downloadLink, price }) => {

    const [currentAccount, setCurrentAccount] = useState(null);
    const [bnbPrice, setBnbPrice] = useState(null);
    const [ownerAddress, setOwnerAddress] = useState(null);
    const [tokenAddress, setTokenAddress] = useState(null);
    const [tokenName, setTokenName] = useState(null);
    const [tokenSymbol, setTokenSymbol] = useState(null);
    const [tokenPrice, setTokenPrice] = useState(null);
    const [tokenEnabled, setTokenEnabled] = useState(null);

    const paymentContract =  process.env.REACT_APP_PAYMENT_CONTRACT;
    const fee = '500000000000000';
    const provider = new ethers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
    const routerAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
    const bnbAddress = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c';
    const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
    const usdxAddress = '0xB62D20f527490D78837c8656f6a28331D7723b34';
    const usdcAddress = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d';

    const fetchConfig = async () => {
        try {
          const response = await axios.get('config.json');
          setOwnerAddress(response.data[0].OwnerWallet);
          setTokenName(response.data[0].TokenName);
          setTokenSymbol(response.data[0].TokenSymbol);
          setTokenAddress(response.data[0].TokenContract);
          setTokenPrice(response.data[0].TokenPrice);
          const boolValue = response.data[0].TokenEnabled === "true" ? true : false;
          setTokenEnabled(boolValue);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
    const getBnbPrice = async () => {
        // Call the getAmountsOut function to get the price of your token in BNB
        const router = new ethers.Contract(routerAddress, pancakeSwapRouterABI, provider);
        router.getAmountsOut(ethers.parseUnits('1', 18), [bnbAddress, usdtAddress])
        .then((result) => {
          const bnbPriceInUSDT = ethers.formatUnits(result[1], 18);
          setBnbPrice(bnbPriceInUSDT);
          // alert(bnbPriceInUSDT);
        })
        .catch((error) => {
          console.error('Error fetching token price:', error);
        });
    }

    const checkWalletIsConnected = async () => {
        const detectedProvider = await detectEthereumProvider();
        if(!detectedProvider){
          console.log("Metamask NOT Installed");
          return;
        }else{
          console.log("Metamask Installed");
        }
    }
    
    const connectWalletHandler = async() => { 
        const detectedProvider = await detectEthereumProvider();
        if(!detectedProvider){
          alert("Please Install Metamask!");
        }
    
        try{
          const accounts = await detectedProvider.request({method: 'eth_requestAccounts'});
          console.log("Found an account :", accounts[0]);
          setCurrentAccount(accounts[0]);
        }catch (err){
          console.log(err);
        }
    }

    useEffect(() => {

      checkWalletIsConnected();
      fetchConfig();
      getBnbPrice();
  
    }, );


    const prePayment = async () => {
        // Check Wallet Connected
        if (currentAccount === null){
          connectWalletHandler();
          return false;
        }
    
        // Check Chain ID
        const detectedProvider = await detectEthereumProvider();
        const chainId = await detectedProvider.request({ method: 'eth_chainId' });
        if(parseInt(chainId, 16) !== 56){
          alert("Please Switch MetaMask to BNB Smart Chain");
          await detectedProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
          return false;
        }

        // await getBnbPrice();
    
        return true;
    }


    // Pay Buttons
  const bnbClick = async () => {
    if(!prePayment()){
      return;
    }
    //prePayment();
    if (currentAccount === null){
      return;
    }
    const bnbAmount = ((Number(price) / Number(bnbPrice)) + 0.0005).toFixed(5);
    console.log('Price: ' + price);
    console.log('BNB price: ' + bnbPrice);
    // alert(bnbAmount);
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.BrowserProvider(detectedProvider);
        // const provider = await detectEthereumProvider();
        const signer = await provider.getSigner();
        // console.log(paymentContract);
        const contract = new ethers.Contract(paymentContract, abi, signer);

        // Get BNB balance
        console.log(currentAccount);
        const balance = await provider.getBalance(currentAccount);
        console.log('Balance: ' + balance);
        console.log('BNB Amount: ' + bnbAmount);
        if(Number(balance) < ethers.parseUnits(bnbAmount, 18)){
          alert('Not enough BNB balance in your wallet');
          return;
        };

        console.log("Intialize payment");
        // console.log(signer);
        let getadrp;

        getadrp = await contract.payBnb(fee, ownerAddress, {value: ethers.parseUnits(bnbAmount, 18)});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdtClick = async () => {
    if(!prePayment()){
      return;
    }
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.BrowserProvider(detectedProvider);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(paymentContract, abi, signer);
        const usdtContractApproval = new ethers.Contract(usdtAddress, ERC20ABI, signer);

        // Get the USDT balance
        const usdtBal = new ethers.Contract(usdtAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.formatUnits(balance, 18) < parseInt(price,10)){
          alert('Not enough USDT balance in your wallet');
          return;
        };

        // Approve USDT Transaction
        const approvalTx = await usdtContractApproval.approve(paymentContract, ethers.parseUnits(price, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDT(ethers.parseUnits(price, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdxClick = async () => {
    if(!prePayment()){
      return;
    }
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.BrowserProvider(detectedProvider);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(paymentContract, abi, signer);
        const usdxContractApproval = new ethers.Contract(usdxAddress, ERC20ABI, signer);

        // Get the USDX balance
        const usdtBal = new ethers.Contract(usdxAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.formatUnits(balance, 18) < parseInt(price,10)){
          alert('Not enough USDX balance in your wallet');
          return;
        };

        // Approve USDX Transaction
        const approvalTx = await usdxContractApproval.approve(paymentContract, ethers.parseUnits(price, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDX(ethers.parseUnits(price, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const usdcClick = async () => {
    if(!prePayment()){
      return;
    }
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();

      if(detectedProvider){
        const provider = new ethers.BrowserProvider(detectedProvider);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(paymentContract, abi, signer);
        const usdcContractApproval = new ethers.Contract(usdcAddress, ERC20ABI, signer);

        // Get the USDC balance
        const usdtBal = new ethers.Contract(usdcAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.formatUnits(balance, 18) < parseInt(price,10)){
          alert('Not enough USDC balance in your wallet');
          return;
        };

        // Approve USDC Transaction
        const approvalTx = await usdcContractApproval.approve(paymentContract, ethers.parseUnits(price, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payUSDC(ethers.parseUnits(price, 18), ownerAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

  const tokenClick = async () => {
    if(!prePayment()){
      return;
    }
    if (currentAccount === null){
      return;
    }
    try{
      const detectedProvider = await detectEthereumProvider();
      const finalPayAmount = (price / Number(tokenPrice)).toFixed(2);

      if(detectedProvider){
        const provider = new ethers.BrowserProvider(detectedProvider);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(paymentContract, abi, signer);
        const tokenContractApproval = new ethers.Contract(tokenAddress, ERC20ABI, signer);

        // Get the Token balance
        const usdtBal = new ethers.Contract(tokenAddress, ERC20ABI, provider);
        const balance = await usdtBal.balanceOf(currentAccount);
        if(ethers.formatUnits(balance, 18) < parseInt(finalPayAmount,10)){
          alert('Not enough token balance in your wallet');
          return;
        };

        // Approve Token Transaction
        const approvalTx = await tokenContractApproval.approve(paymentContract, ethers.parseUnits(finalPayAmount, 18));
        await approvalTx.wait();
        console.log('Approval confirmed');

        console.log("Intialize payment");
        let getadrp;

        getadrp = await contract.payToken(ethers.parseUnits(finalPayAmount, 18), ownerAddress, tokenAddress, {value: fee});

        if(getadrp){
          window.location.href = downloadLink;
        }else{
          alert("Something wrong, Maybe you don't have enough balance for the transaction.");
        }
          }
        }catch(err){
        alert("Something wrong, Please try again later.");
          console.log(err);
        }
  }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Pay : ${price}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Select preferred payment method :</p>

                {tokenEnabled &&
                <>
                <Button variant="secondary m-2" onClick={tokenClick}>
                  <img src="icons/token.png" height={25} width={25} alt="Token Icon" className="button-icon me-2" />
                    {tokenSymbol}
                </Button>
                </> }
                <Button variant="secondary m-2" onClick={bnbClick}>
                    <img src="icons/bnb.png" height={25} width={25} alt="BNB Icon" className="button-icon me-2" />
                    BNB
                </Button>
                <Button variant="secondary m-2" onClick={usdtClick}>
                    <img src="icons/usdt.png" height={25} width={25} alt="USDT Icon" className="button-icon me-2" />
                    USDT
                </Button>
                <Button variant="secondary m-2" onClick={usdxClick}>
                  <img src="icons/usdx.png" height={25} width={25} alt="USDX Icon" className="button-icon me-2" />
                    USDX
                </Button>
                <Button variant="secondary m-2" onClick={usdcClick}>
                    <img src="icons/usdc.png" height={25} width={25} alt="USDC Icon" className="button-icon me-2" />
                    USDC
                </Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentModal;
