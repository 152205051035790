import React from 'react';

const Footer = ({ telegram, facebook, twitter, instagram }) => {

    return (
        <footer className="footer py-3 mt-4">
            <div className="container d-flex justify-content-between align-items-center">
                <p className="mb-0 font-weight-bold text-white">Powered by 0xFactory.com</p>
                <div className="social-icons">
                    <a href={telegram} target="_blank" rel="noopener noreferrer" className="text-white me-3">
                        <i className="fab fa-telegram"></i>
                    </a>
                    <a href={twitter} target="_blank" rel="noopener noreferrer" className="text-white me-3">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href={instagram} target="_blank" rel="noopener noreferrer" className="text-white me-3">
                        <i className="fab fa-instagram"></i>
                    </a>
                    <a href={facebook} target="_blank" rel="noopener noreferrer" className="text-white">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
